import { axiosInstance } from './base';

export function sendWeeklyEmail(data, requestDate) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.post(`email`, data, { params: { requestDate: requestDate }, headers });
}

export function sentWeeklyEmailTasks(data) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.post(`email-tasks`, data, { headers });
}

export function getWeeklyEmailsLogs(projectId, month) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`projects/${projectId}/weekly-emails`, { params: { month: month }, headers });
}
// export function getWeeklyEmails(projectId) {
//   return axios.get(`${process.env.VUE_APP_PROJECT_TRACKER_API_URL}email/${projectId}`)
// }

export function getWeeklyEmails(projectId, page, totalCount) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`projects/${projectId}/get-paginated-email-list`, {
        params: { sort: { createdAt: -1 }, limit: 10, page, totalCount },
        headers,
    });
}

export function getWeeklyEmailsGeneralTickets(projectId, status, apiKey) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`general-task-ticket/${projectId}`, {
        params: { status: status, apiKey: apiKey },
        headers,
    });
}

export function getWeeklyEmailsTaskDetails(emailId) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`email/${emailId}/task-radar-details`, {
        headers,
    });
}

export function getWeeklyEmailsTaskRadar(emailId) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`email/${emailId}/tasks-radar`, {
        headers,
    });
}

export function getWeeklyEmailsTaskLog(emailId) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`email/${emailId}/emailed-tasks/logs`, {
        headers,
    });
}

export function getWeeklyEmailsChattyLog(emailId) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`email/${emailId}/chatty/logs`, {
        headers,
    });
}

export function getWeeklyEmailsGeekyLog(emailId) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`email/${emailId}/geeky/logs`, {
        headers,
    });
}
