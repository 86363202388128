/*global process*/
/*eslint no-undef: "error"*/

import { axiosInstance, axiosTrelloInstance } from './base';

export function getTrelloBoards() {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosTrelloInstance.get(
        `members/me/boards?key=${process.env.VUE_APP_TRELLO_API_KEY}&token=${process.env.VUE_APP_TRELLO_API_TOKEN}`,
        { headers }
    );
}

export function getTrelloBoardList(boardId) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosTrelloInstance.get(
        `boards/${boardId}/lists?key=${process.env.VUE_APP_TRELLO_API_KEY}&token=${process.env.VUE_APP_TRELLO_API_TOKEN}`,
        { headers }
    );
}

export function getTrelloCardsListById(projectId, filter, page = 1, limit = 10) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`trello-task/project/${projectId}/get-trello-cards`, {
        params: { filter: filter, limit: limit, page, sort: { createdAt: -1 } },
        headers,
    });
}
