/*global process*/
/*eslint no-undef: "error"*/

import { axiosInstance, axiosAsanaInstance } from './base';

export function getAsanaProjects() {
    const headers = {
        Authorization: `Bearer ${process.env.VUE_APP_ASANA_API_TOKEN}`,
    };
    const headerToken = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };

    return axiosAsanaInstance.get(`projects`, { headers }, { headerToken });
}

export function getAsanaProjectSections(projId) {
    const headers = {
        Authorization: `Bearer ${process.env.VUE_APP_ASANA_API_TOKEN}`,
    };
    const headerToken = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };

    return axiosAsanaInstance.get(`projects/${projId}/sections`, { headers }, { headerToken });
}

export function getPaginatiedAsanaTasks(projectId, filter = {}, page = 1, limit = 1000) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`projects/${projectId}/get-paginated-asana-tasks`, {
        params: { filter: filter, limit: limit, page, sort: { createdAt: -1 } },
        headers,
    });
}

export function getAsanaTaskStories(taskId) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`asana-task-stories/task/${taskId}`, { headers });
}

export function getAsanaTasksByProject(projectId, page) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`projects/${projectId}/get-paginated-asana-tasks`, {
        params: { sort: { createdAt: -1 }, limit: 10, page },
        headers,
    });
}

export function getAsanaTasks(sectionGid) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`asana-task/section/${sectionGid}`, {
        headers,
    });
}

export function getPaginatedAsanaTasks(projectId, filter = {}, section_name, done_since, page = 1, limit = 1000) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`projects/${projectId}/asana-tasks`, {
        params: {
            filter: filter,
            limit: limit,
            page,
            sort: { createdAt: -1 },
            section_name: section_name,
            done_since: done_since,
        },
        headers,
    });
}

export function syncTasks(projectId, sections, completed_since = {}, data) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.post(`asana/projects/${projectId}/sync-tasks`, data, {
        // timeout: 0,
        params: {
            sections: sections,
            completed_since: completed_since,
        },
        headers,
    });
}
