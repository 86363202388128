import { axiosInstance } from './base';

export function getPaginatedJiraIssues(projectId, filter = {}, page = 1, limit = 1000) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`projects/${projectId}/get-paginated-jira-issues`, {
        params: { filter: filter, limit: limit, page, sort: { updatedAt: -1 } },
        headers,
    });
}

export function getJiraSections(projectId) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`projects/${projectId}/get-jira-sections`, {
        headers,
    });
}
