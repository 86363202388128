import { axiosInstance } from './base';

export function getSlackChannels(limit = 1000) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`slack/get-slack-channels`, {
        params: { limit },
        headers,
    });
}

export function getSlackMessages() {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`slack/messages`, { headers });
}
export function getSlackProjectMessages({ filter, sort = { createdAt: -1 }, page = 1, totalCount, limit = 10 }) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`projects/${filter.project}/get-slack-messages`, {
        params: { filter, sort, page, totalCount, limit },
        headers,
    });
}

export function getSlackMessagesByChannel(channelId) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`slack/messages/channel/${channelId}`, { headers });
}
