import { axiosInstance } from './base';

export function getProjects() {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`projects`, { headers });
}

export function getProjectsById(id) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`projects/${id}`, { headers });
}

export function getProjectMonitoringById(id) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`projects/${id}/monitoring`, { headers });
}

export function updateProjectsById(id, data) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.patch(`projects/${id}`, data, { headers });
}

export function postProjects(data) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.post(`projects`, data, { headers });
}

export function getProjectsByType(project_type) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`projects/type/${project_type}`, { headers });
}

export function archiveProjects(id) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.patch(`projects/archive/${id}`, { headers });
}

export function getUserProjectsWithTimelogs(userId) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`timelogs/projects/email/${userId}`, { headers });
}

export function getProjectsApiKeyById(id) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`projects/api-key/${id}`, { headers });
}

export function getProjectMonitoringHistogram(id, month) {
    const headers = { Authorization: `Bearer ${localStorage.getItem('auth_token')}` };
    return axiosInstance.get(`projects/${id}/histogram`, { params: { month: month }, headers });
}
